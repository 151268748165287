import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
// import ContactPageComp from "../components/contactPageComp"
import sideImageL from "../images/siderailimg.png"
import sideImageR from "../images/siderailimg.png"

import "../components/static/styles/contactPage.css"

const divBackgroundStyles = {
    backgroundColor: `#e5e5e8`,
    paddingBottom: `1rem`,
  }

  const divMainStyles = {
    backgroundColor: `#ff5722`,
    display: `grid`,
    gridTemplateRows: `4rem repeat(3, 6rem) 11rem`,
    gridTemplateColumns: `1fr`,
    marginTop: `3rem`,
    alignItems: `center`,
  }

  const paraStyles = {
    margin: `1rem 0`,
    textAlign: `center`,
  }

export default class Contact extends Component {
  render() {
    return (
      <Layout>
        <SEO title="Contact Us" />
          <div style={divBackgroundStyles} className="jumbo__contact">
          <img
            src={sideImageL}
            alt="sideBanner"
            className="sideImageLContactUs"
          />
        <div style={divMainStyles} className="holder__contact">
          <h1 style={{ textAlign: "center" }}>Contact Us</h1>
          <p style={paraStyles}>
            Demonstration website
          </p>
          <p style={paraStyles}>
            For order enquiries please use same email as was submitted when
            placing order{" "}
          </p>
          <p style={paraStyles}>
            Feel free to bookmark the <a href="/"> Home page</a> to save details
            for future reference
          </p>
          <span
            style={{
              margin: "2rem 0rem",
              display: `flex`,
              flexDirection: `column`,
              alignItems: `center`,
            }}
          >
          </span>
          </div>
          <img
            src={sideImageR}
            alt="sideBanner"
            className="sideImageRContactUs"
          />
        </div>
      </Layout>
    )
  }
}
